import {BootstrapTable, Actions, ActionsColumn, Column, IdColumn} from 'react-admin-base-adminkit';
import React, { useState } from 'react';
import { useDataTableContext } from 'react-admin-base-adminkit';
import {Breadcrumb} from 'react-admin-base-adminkit';
import {Card, CardBody, Col, CustomInput} from "reactstrap";
import {ProductTypeSelect} from "../../Components/Selects";
import CategorySelect from "../../Components/CategorySelect";

function ProductTypeFilter() {
    const [ productType, setProductType ] = useState(null);
    const [ params, setParams ] = useDataTableContext();

    return <ProductTypeSelect
        value={productType}
        onChange={a => {
            setProductType(a);
            setParams({ ...params, product_type_id: (a && a.id) || null });
        }}
        placeholder="Ürün Türüne Göre"
    />;
}

function CategoryFilter() {
    const [ category, setCategory ] = useState(null);
    const [ params, setParams ] = useDataTableContext();

    return <CategorySelect
        value={category}
        onChange={a => {
            setCategory(a);
            setParams({ ...params, category_id: (a && a.id) || null });
        }}
        placeholder="Kategoriye Göre"
    />;
}

function AktivFilter() {
    const [ params, setParams ] = useDataTableContext();
    return <CustomInput
        type="checkbox"
        id="filter_Enabled"
        className="mt-1"
        label="Aktiv"
        checked={!!params.enabled}
        onChange={a => setParams({ enabled: !params.enabled })}
    />;
}

export function ProductFilters() {
    return <>
        <Col>
            <ProductTypeFilter />
        </Col>
        <Col>
            <CategoryFilter />
        </Col>
        <Col md="auto">
            <AktivFilter />
        </Col>
    </>;
}

export function AktivShow({ value }) {
    return value ? <i className="fas fa-check-circle text-success" /> : <i className="fas fa-times-circle text-danger" />;
}

export default function ProductTypeList() {
    return <Breadcrumb
        title="Ürün"
        data={
            [
                {
                    href: '/product',
                    name: 'Ürünler'
                }
            ]
        }
    >
        <Card>
            <CardBody>
                <BootstrapTable url="/product" add="/product/create">
                    <thead>
                    <tr>
                        <IdColumn />
                        <Column sort="active" className="text-center min">Aktif</Column>
                        <Column className="min">Türü</Column>
                        <Column sort="name">Adı</Column>
                        <ActionsColumn />
                    </tr>
                    </thead>
                    <tbody>
                    {
                        row => <tr key={row.id}>
                            <td className="min text-center">{row.id}</td>
                            <td className="text-center">
                                <AktivShow value={row.active} />
                            </td>
                            <td>{ row.product_type.name }</td>
                            <td>{row.name}</td>
                            <Actions
                                edit={"/product/" + row.id}
                                del={"/product/" + row.id}
                            />
                        </tr>
                    }
                    </tbody>
                    <ProductFilters />
                </BootstrapTable>
            </CardBody>
        </Card>
    </Breadcrumb>;
}
