import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {ModalEntityEditor} from "../../Components/CRUD";
import {useUser} from "../../Components/UserProvider";
import {useEntity} from "react-admin-base";
import {Validator, SingleFilePicker} from "react-admin-base-adminkit";

export default function SupplierEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/supplier', id, {});
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor title="Tedarikçi Yönetim" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>İsim</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name || ''}
                        onChange={a => setData({name: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Logo</Label>
                <SingleFilePicker
                    accepts="image/*"
                    value={data.logo}
                    onChange={logo => setData({logo})}
                />
            </FormGroup>
            <FormGroup>
                <Label>Website</Label>
                <Validator name="website" type="url">
                    <Input
                        type="text"
                        value={data.website || ''}
                        onChange={a => setData({website: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Adres</Label>
                <Input
                    type="textarea"
                    rows={3}
                    value={data.address || ''}
                    onChange={a => setData({address: a.currentTarget.value})}
                />
            </FormGroup>
            <FormGroup>
                <Label>Telefon</Label>
                <Input
                    type="text"
                    value={data.phone || ''}
                    onChange={a => setData({phone: a.currentTarget.value})}
                />
            </FormGroup>
            <FormGroup>
                <Label>E-Mail</Label>
                <Validator name="email" type="email">
                    <Input
                        type="text"
                        value={data.email || ''}
                        onChange={a => setData({email: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}
