import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {ModalEntityEditor} from "../../Components/CRUD";
import {useUser} from "../../Components/UserProvider";
import {useEntity} from "react-admin-base";
import {Validator, SingleFilePicker} from "react-admin-base-adminkit";

export default function BrandEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/brand', id, {type: user.type});
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor title="Marka Yönetim" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Marka</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name || ''}
                        onChange={a => setData({name: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Logo</Label>
                <SingleFilePicker
                    accepts="image/*"
                    value={data.logo}
                    onChange={logo => setData({logo})}
                />
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}
