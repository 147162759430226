import React from 'react';
import {Card, CardBody} from "reactstrap";
import CampaignEntity from "./CampaignEntity";
import CRUD, {CRUDActions} from "../../Components/CRUD";
import {useUser} from "../../Components/UserProvider";
import {ActionsColumn, Column, IdColumn, Relative, Breadcrumb} from "react-admin-base-adminkit";
import {AktivShow} from "../Product/List";

export default function Campaigns() {
    const user = useUser();

    return <Breadcrumb
        data={
            [
                {
                    href: '/campaign',
                    name: 'Kampanyalar'
                }
            ]
        }
    >
        <Card>
            <CardBody>
                <CRUD Component={CampaignEntity} url="/campaign">
                    <thead>
                    <tr>
                        <IdColumn/>
                        <Column>Sıra</Column>
                        <Column>Aktif</Column>
                        <Column sort="name">İsim</Column>
                        <Column sort="url">URL</Column>
                        <Column>Fotoğraf</Column>
                        <ActionsColumn/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        row => <tr key={row.id}>
                            <td className="min text-center">{row.id}</td>
                            <td className="min text-center">{row.order}</td>
                            <td className="min text-center">
                                <AktivShow value={row.active} />
                            </td>
                            <td>{row.title}</td>
                            <td>{row.url}</td>
                            <td>
                                { row.photo && <Relative>
                                    <img width={"15%"}
                                         src={row.photo.access_url} alt={row.name}/>
                                </Relative> }
                            </td>
                            <CRUDActions
                                id={row.id}
                                edit={user.type === 512}
                                del={(user.type === 512) && "/campaign/" + row.id}
                            />
                        </tr>
                    }
                    </tbody>
                </CRUD>
            </CardBody>
        </Card>
    </Breadcrumb>;
}
