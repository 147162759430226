import React, {useEffect, useState} from 'react';
import {ApiSelect} from 'react-admin-base-adminkit';
import {useAuth} from 'react-admin-base';

function useParentCategories(id) {
  const [ api ] = useAuth();
  const [ keys, setKeys ] = useState([]);

  useEffect(function() {
    async function update() {
      let data;
      let keys = [];

      do {
        data = (await api.tokenized.get('/category/' + ((data && data.parent_id) || id))).data;
        keys.push(data);
      } while(data.parent_id);

      setKeys(keys);
    }

    if (id) {
      update();
    } else {
      setKeys([]);
    }
  }, [ api, id ]);

  return keys;
}

function SingleCategorySelect(props) {
  return <ApiSelect
    url={"/category?parent_id=" + (props.parent_id || '')}
    {...props}
  />
}

export default function CategorySelect(props) {
  const { onChange } = props;

  const categories = useParentCategories(props.value && props.value.id);

  const arr  = [...categories]
      .reverse();

  const arr2 = arr
      .map((category, index) => ({
        prev: arr[index - 1],
        cur: category
      }))
      .concat(!categories[0] || !!categories[0].childeren_count ? [{
        prev: categories[0],
        cur: null
      }] : []);

  return arr2
      .map(({ prev, cur }, index) => <div key={index} className={index !== arr2.length - 1 ? "mb-2" : ""}>
        <SingleCategorySelect
          parent_id={prev && prev.id}
          value={cur}
          onChange={(a) => onChange(a || prev || null)}
          placeholder={props.placeholder}
        />
      </div>);
}
