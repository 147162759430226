import React from 'react';
import {Sidebar, Menu, MenuGroup} from 'react-admin-base-adminkit';
import {useUser} from "./Components/UserProvider";

export default function MenuSidebar() {
    const user = useUser();

    return <Sidebar>
        <MenuGroup title="Sayfalar">
            <Menu title="Anasayfa" to="/dashboard/index" icon="fas fa-tachometer-alt"/>

            {user.type === 512 && <Menu title="Ürün Tipleri" to="/product-type" icon="fas fa-sliders-h"/>}
            {user.type === 512 && <Menu title="Markalar" to="/brand" icon="fas fa-copyright"/>}
            {user.type === 512 && <Menu title="Kategoriler" to="/category" icon="fas fa-folder-open"/>}
            {user.type === 512 && <Menu title="Ürünler" to="/product" icon="fas fa-database"/>}
            {user.type === 512 && <Menu title="Tedarikçiler" to="/supplier" icon="fas fa-handshake"/>}
            {user.type === 256 && <Menu title="Teklifler" to="/bids" icon="fas fa-comment-alt"/>}
            {user.type === 512 && <Menu title="Kampanyalar" to="/campaign" icon="fas fa-thumbs-up"/>}

            {user.supplier !== null && <Menu title="Malzemeler" to="/supplies" icon="fas fa-database"/>}

            {user.type >= 256 && <Menu title="Kullanıcılar" to="/user" icon="fas fa-users"/>}
            <Menu title="Profilim" to="/profile" icon="fas fa-user"/>

        </MenuGroup>
    </Sidebar>;
}
