import React from 'react';
import {EntityEditor} from 'react-admin-base-adminkit';
import {Card, CardBody, Col, Input, Label, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import {Breadcrumb} from 'react-admin-base-adminkit';
import {Redirect} from 'react-router-dom';
import {Validator} from 'react-admin-base-adminkit';
import {useUser} from "../../Components/UserProvider";
import {SupplierSelect} from "../../Components/Selects";

export const roles = {
    '512': 'Admin',
    '256': 'Tedarikçi',
    '128': 'Kullanıcı'
};

export default function EditCreate({match}) {
    const id = match.params.id;

    const user = useUser();
    const entity = useEntity('/user', id, { type: user.type });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <Breadcrumb
        title="Kullanıcılar"
        data={
            [
                {
                    href: '/user',
                    name: 'Kullanıcılar'
                },
                data.id && {
                    href: '/user/' + data.id,
                    name: data.first_name
                },
                !data.id && {
                    href: '/user/create',
                    name: 'Ekle'
                }
            ]
        }
    >
        { data && data.id && +id !== +data.id && <Redirect to={"/user/" + data.id}/> }
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">İsim</Label>
                        </Col>
                        <Col md="4">
                            <Validator name="name" type="required">
                                <Input
                                    type="text"
                                    value={data.first_name}
                                    onChange={a => setData({first_name: a.currentTarget.value})}
                                />
                            </Validator>
                        </Col>
                        <Col md="2">
                            <Label htmlFor="text-input">Soyisim</Label>
                        </Col>
                        <Col md="4">
                            <Validator name="surname" type="required">
                                <Input
                                    type="text"
                                    value={data.last_name}
                                    onChange={a => setData({last_name: a.currentTarget.value})}
                                />
                            </Validator>
                        </Col>

                </Row>
                    <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">E-Mail Adres</Label>
                        </Col>
                        <Col md="10">
                            <Validator name="email" type="required">
                                <Input type="text" value={data.email}
                                       onChange={a => setData({email: a.currentTarget.value})}/>
                            </Validator>
                        </Col>

                    </Row>
                    <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">Şifre</Label>
                        </Col>
                        <Col md="10">
                            <Input
                                type="password"
                                value={data.password}
                                autocomplete="new-password"
                                onChange={a => setData({password: a.currentTarget.value})}
                            />
                        </Col>
                    </Row>
                    { user.type === 512 && <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">Rol</Label>
                        </Col>
                        <Col md="10">
                            <Input
                                type="select"
                                value={data.type}
                                onChange={a => setData({type: +a.currentTarget.value, supplier:null})}
                            >
                                { Object.entries(roles).filter(([id]) => id <= user.type).map(([id, role]) => <option value={id} key={id}>{role}</option>)}
                            </Input>
                        </Col>
                    </Row> }
                    { user.type === 512 && data.type === 256 && <Row>
                        <Col md="2">
                            <Label htmlFor="text-input">Tedarikçi</Label>
                        </Col>
                        <Col md="10">
                            <Validator name="supplier" type="required">
                                <SupplierSelect
                                    value={data.supplier}
                                    onChange={a => setData({ supplier: a })}
                                />
                            </Validator>
                        </Col>
                    </Row>}
                </CardBody>
            </Card>
        </EntityEditor>
    </Breadcrumb>;
}
