
import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import { useAuth } from 'react-admin-base';

const WebSocketContext = React.createContext();

export default function WebSocketProvider({ children }) {
    const [ api ] = useAuth();
    const obj = useMemo(() => ({}), []);

    useEffect(function() {
        return listen_message(api, function(str) {
            const _obj = JSON.parse(str);
            if (obj[_obj.type]) {
                (obj[_obj.type])(_obj.body);
            }
        });
    }, [api, obj]);

    return <WebSocketContext.Provider value={obj}>
        { children }
    </WebSocketContext.Provider>;
}

export function useWebSocket(topic, _cb, deps) {
    const ws = useContext(WebSocketContext);
    console.log('Test: ', ws);

    const cb = useCallback(_cb, deps);

    useEffect(function() {
        //const old_cb = cb;
        const old_topic = topic;

        ws[topic] = cb;

        return function() {
            delete ws[old_topic];
        };
    }, [ws, topic, cb]);

    return ws;
}

async function listen_message(api, listener) {
    const res = await api.tokenized.get('/ws/start');
    const start_url = res.data;

    var ws = new WebSocket(process.env.REACT_APP_ENDPOINT.replace(/^http:/, 'ws:').replace(/^https:/, 'wss:').replace(/\/$/, '') + start_url);
    ws.onmessage = function(a) {
        listener(a.data);
    };

    return function() {
        ws.close();
        console.log('Close WebSocket.');
    };
}
