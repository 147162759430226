import React from 'react';
import {Breadcrumb} from 'react-admin-base-adminkit';
import {Card, CardBody} from "reactstrap";

export default function Dashboard() {
    return <Breadcrumb
        title="Anasayfa"
        data={
            [
                {
                    href: '/dashboard',
                    name: 'Anasayfa'
                }
            ]
        }
    >
        <Card>
            <CardBody>
                Anasayfa
            </CardBody>
        </Card>

    </Breadcrumb>;
}
