import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import Dashboard from "./pages/Dash/Dashboard";

import Profile from "./pages/Profile/Edit";

import UserEC from "./pages/User/EditCreate";
import User from "./pages/User/List";

import ProductTypeEC from "./pages/ProductType/EditCreate";
import ProductType from "./pages/ProductType/List"

import ProductEC from "./pages/Product/EditCreate";
import ProductList from "./pages/Product/List"

import CategoryList from "./pages/Category/List";
import CategoryEdit from "./pages/Category/Edit";
import Brands from "./pages/Brand/Brands";

import {useUser} from "./Components/UserProvider";
import Suppliers from "./pages/Supplier/Suppliers";
import Supplies from "./pages/Supplies/Supplies";
import Bids from "./pages/Bids/Bids";
import Campaigns from "./pages/Campaign/Campaigns";

export default function Router() {
    const user = useUser();

    return <Switch>
        <Redirect exact from="/" to="/dashboard"/>
        <Redirect exact from="/login" to="/dashboard"/>
        <Redirect exact from="/register" to="/dashboard"/>
        <Redirect exact from="/logout" to="/dashboard"/>

        <Route path="/dashboard" component={Dashboard}/>

        { user.type === 512 && <Route path="/product-type/create" component={ProductTypeEC}/> }
        { user.type === 512 && <Route path="/product-type/:id" component={ProductTypeEC}/> }
        { user.type === 512 && <Route path="/product-type" component={ProductType}/> }

        { user.type === 512 && <Route path="/product/create" component={ProductEC}/> }
        { user.type === 512 && <Route path="/product/:id" component={ProductEC}/> }
        { user.type === 512 && <Route path="/product" component={ProductList}/> }

        { user.type === 256 && <Route path="/bids" component={Bids}/> }

        { user.supplier !== null && <Route path="/supplies" component={Supplies}/> }

        { user.type === 512 && <Route path="/brand" component={Brands}/> }
        { user.type === 512 && <Route path="/supplier" component={Suppliers}/> }
        { user.type === 512 && <Route path="/campaign" component={Campaigns}/> }

        { user.type === 512 && <Route path="/category/create" component={CategoryEdit}/> }
        { user.type === 512 && <Route path="/category/:parent_id/create" component={CategoryEdit}/> }
        { user.type === 512 && <Route path="/category/:id/edit" component={CategoryEdit}/> }
        { user.type === 512 && <Route path="/category/:id" component={CategoryList}/> }
        { user.type === 512 && <Route path="/category" component={CategoryList}/> }

        { user.type >= 256 && <Route path="/user/create" component={UserEC}/>}
        { user.type >= 256 && <Route path="/user/:id" component={UserEC}/>}
        { user.type >= 256 && <Route path="/user" component={User}/>}

        <Route path="/profile" component={Profile}/>

        <Redirect to="/dashboard"/>
    </Switch>;
}
