import React from 'react';
import {EntityEditor} from 'react-admin-base-adminkit';
import {Button, Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import {Breadcrumb} from 'react-admin-base-adminkit';
import {Redirect} from 'react-router-dom';
import {Validator} from 'react-admin-base-adminkit';
import Orderable from "../../Components/Orderable";
import ModalPortlet from "../../Components/ModalPortlet";

var total = 0;

class PropertyValue extends React.Component {
    handleChange(model) {
        this.props.onChange({ ...this.props.value, ...model });
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.value !== this.props.value;
    }

    render() {
        const { value } = this.props;

        return <Row>
            <Col md="3" className="align-self-center">
                <Label htmlFor="text-input">Option {this.props.index}.</Label>
            </Col>
            <Col xs="12" md="7">
                <Input type="text" value={value.value || ""}
                       onChange={a => this.handleChange({ value: a.currentTarget.value })} />
            </Col>
            <Col xs="12" md="2">
                <Button color="danger" onClick={a => this.props.onDelete()}>Sil</Button>
            </Col>
        </Row>;
    }
}

class Property extends React.Component {
    handleChange(model) {
        this.props.onChange({ ...this.props.property, ...model });
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.property !== this.props.property;
    }

    render() {
        const { property } = this.props;

        return <div className="mb-2">
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label>Isim</Label>
                        <Input type="text"
                               value={property.name || ""}
                               onChange={a => this.handleChange({ name: a.currentTarget.value })}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label>Tur</Label>
                        <Input type="select" value={property.type} onChange={a => this.handleChange({ type: +a.currentTarget.value })}>
                            <option value={1}>Ja/Nien</option>
                            <option value={2}>Text</option>
                            <option value={3}>Option</option>
                            <option value={4}>Multi Select Option</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            { (property.type == 3 || property.type == 4) && <>
                <Orderable value={property.values} onChange={values => this.handleChange({ values })}>
                    {(value) => <div style={{padding: "5px 0 5px 25px"}}>
                        <PropertyValue
                            value={value}
                            onChange={newValue => this.handleChange({ values: property.values
                                    .map(oldValue => oldValue === value ? newValue : oldValue) })}
                            onDelete={() => this.handleChange({ values: property.values.filter(a => a !== value) })}
                        />
                    </div>}
                </Orderable>

                <Button
                    className="mr-2"
                    color="primary"
                    onClick={a => this.handleChange({ values: (property.values || []).concat([{  }]) })}>Value Ekle</Button>
            </>}

            <Button color="danger" onClick={a => this.props.onDelete()}>Sil</Button>
        </div>;
    }
}

export class Group extends React.Component {
    handleChange(model) {
        this.props.onChange({ ...this.props.group, ...model });
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.group !== this.props.group;
    }

    render() {
        const { group } = this.props;

        return <div className="mb-2">
            <ModalPortlet onDelete={this.props.onDelete} title={<>
                <Col md="auto" className="align-self-center">
                    <Label className="mb-0"><b>Grup Adi</b></Label>
                </Col>
                <Col>
                    <Input type="text"
                           value={group.name || ""}
                           onChange={a => this.handleChange({ name: a.currentTarget.value })}
                    />
                </Col>
            </>}>
                <Orderable id="property" value={group.attributes} onChange={attributes => this.handleChange({ attributes })}>
                    {(property) => <div style={{padding: "5px 0 5px 25px"}}>
                        <Property
                            key={property.id}
                            property={property}
                            onChange={newProperty => this.handleChange({ attributes: group.attributes
                                    .map((oldProperty) => oldProperty === property ? newProperty : oldProperty) })}
                            onDelete={() => this.handleChange({ attributes: group.attributes.filter(a => a !== property) })}
                        />
                    </div>}
                </Orderable>
                <Button
                    color="primary"
                    onClick={a => this.handleChange({ attributes: (group.attributes || []).concat([{ type: 1 }]) }) }>
                    Property Ekle
                </Button>
                <Button color="danger" onClick={a => this.props.onDelete()}>Group Sil</Button>
            </ModalPortlet>
        </div>;
    }
}
export default function EditCreate({match}) {
    const id = match.params.id;

    const entity = useEntity('/product_type', id);
    const [data, setData] = entity;

    return <Breadcrumb
        title="Product Type"
        data={
            [
                {
                    href: '/product-types',
                    name: 'Ürün Tipleri'
                }
            ]
        }
    >
        { data && data.id && +id !== +data.id && <Redirect to={"/product-type/" + data.id}/> }
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">İsim</Label>
                        </Col>
                        <Col md="4">
                            <Validator name="name" type="required">
                                <Input
                                    type="text"
                                    value={data.name || ''}
                                    onChange={a => setData({name: a.currentTarget.value})}
                                />
                            </Validator>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Orderable value={data.groups} onChange={groups => setData({ groups })}>
                {(group) => <div>
                    <Group
                        group={group}
                        onChange={a => setData({
                            groups: data.groups
                                .map(b => b === group ? a : b)
                        })}
                        onDelete={() => setData({
                            groups: data.groups.filter(a => a !== group) })}
                    />
                </div>}
            </Orderable>
            <Button
                type="button"
                color="primary"
                className="mb-3"
                onClick={a => setData({ groups: (data.groups || []).concat([{ }]) })}>Group Ekle</Button>
        </EntityEditor>
    </Breadcrumb>;
}
