
import React, {useEffect, useState} from 'react';
import { useFetch, useAuth } from 'react-admin-base';
import {useWebSocket} from "../../Components/WebSocketProvider";

export default function useBids() {
    const [ api ] = useAuth();
    const [ bids, setBids ] = useState(null);

    useEffect(function() {
        async function update() {
            const res = await api.tokenized.get('/bids');
            setBids(res.data);
        }

        update();
    }, [api, setBids]);

    useWebSocket('bid', function(bid) {
        setBids(oldBids => oldBids.concat([bid]));
    }, [setBids]);

    useWebSocket('bid_message', function(msg) {
        setBids(oldBids => oldBids.map(a => msg.bid_id === a.id ? {...a, messages: a.messages.concat([msg])} : a));
    }, [setBids]);

    return bids;
}