import React, {Component} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default class Orderable extends Component {
    onDragEnd(result) {
        if (!result.destination) {
        return;
        }

        this.props.onChange(reorder(
            this.props.value,
            result.source.index,
            result.destination.index
        ));
    }

    render() {
        return <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
            <Droppable droppableId={this.props.id || 'random-draggable'}>
            {(provided, snapshot) => (
                <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ 'background': snapshot.isDraggingOver ? 'lightblue' : 'inherit' }}
                >
                {(this.props.value || []).map((value, i) =>
                    <Draggable key={value.id || -i} draggableId={(value.id || -i).toString()} index={i}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{ 'background': snapshot.isDragging ? 'yellow' : 'inherit', ...provided.draggableProps.style }}>
                            { this.props.children(value, i) }
                        </div>)}
                    </Draggable>
                )}
                {provided.placeholder}
                </div>
            )}
            </Droppable>
        </DragDropContext>;
    }
}

const newItem = Symbol();

export class Orderable2 extends Component {
    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if(!this.props.value)
            return null;

        this.props.onChange(reorder(
            this.props.value,
            result.source.index,
            result.destination.index
        ));
    }

    render() {
        return <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
            <Droppable droppableId={this.props.id || 'random-draggable'}>
            {(provided, snapshot) => (
                <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ 'background': snapshot.isDraggingOver ? 'lightblue' : 'inherit' }}
                >
                {(this.props.value || []).concat([newItem]).map((value, i) =>
                    <Draggable key={value.id || -i} draggableId={(value.id || -i).toString()} isDragDisabled={ value === newItem } index={i}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{ 'background': snapshot.isDragging ? 'yellow' : 'inherit', ...provided.draggableProps.style }}>
                            { this.props.children(value === newItem ? null : value, i, {
                                onChange: (val) => this.props.onChange(value === newItem ? (this.props.value || []).concat([ val ]) : this.props.value.map(b => b === value ? val : b)),
                                onDelete: () => this.props.onChange(this.props.value.filter(a => a !== value))

                            }) }
                        </div>)}
                    </Draggable>
                )}
                {provided.placeholder}
                </div>
            )}
            </Droppable>
        </DragDropContext>;
    }
}