
import React, { useEffect, useState } from 'react';
import {Card, CardBody} from "reactstrap";
import {Link} from "react-router-dom";
import {Breadcrumb, BootstrapTable, Actions, ActionsColumn, Column, IdColumn} from 'react-admin-base-adminkit';
import {useAuth} from "react-admin-base";

export function useCategoryParents(id) {
    const [ parentCategories, setParentCategories ] = useState(null);
    const [ api ] = useAuth();

    useEffect(function() {
        async function update() {
            var parents = [];
            var cid = id;

            do {
                var category = await api.tokenized.get("/category/" + cid);
                parents.push(category.data);
                cid = category.data.parent_id;
            } while(category.data.parent);

            setParentCategories(parents.reverse());
        }

        if (!id) {
            setParentCategories([]);
        } else {
            update();
        }
    }, [api, id]);

    return parentCategories;
}

export default function Category({ match }) {
    const id = match.params.id;

    const parentCategories = useCategoryParents(id);
    if (!parentCategories)
        return null;

    return <Breadcrumb
        data={
            [
                {
                    href: '/category',
                    name: 'Kategoriler'
                }
            ].concat(parentCategories.map(category => ({
                href: '/category/' + category.id,
                name: category.name
            })))
        }
    >
        <Card>
            <CardBody>
                <BootstrapTable url={!id ? "/category" : "/category?parent_id=" + id} add={!id ? "/category/create" : "/category/" + id + "/create"}>
                    <thead>
                        <tr>
                            <IdColumn />
                            <Column sort="order">Sıra</Column>
                            <Column sort="name">İsim</Column>
                            <ActionsColumn />
                        </tr>
                    </thead>
                    <tbody>
                    {
                        row => <tr key={row.id}>
                            <td className="min text-center">{row.id}</td>
                            <td>{row.order}</td>
                            <td><Link to={"/category/" + row.id}>{row.name}</Link></td>
                            <Actions
                                edit={"/category/" + row.id + "/edit"}
                                del={"/category/" + row.id}
                            />
                        </tr>
                    }
                    </tbody>
                    <>

                        </>
                </BootstrapTable>
            </CardBody>
        </Card>
    </Breadcrumb>;
}
