import React from 'react';
import {Card, CardBody} from "reactstrap";
import SupplierEntity from "./SupplierEntity";
import CRUD, {CRUDActions} from "../../Components/CRUD";
import {useUser} from "../../Components/UserProvider";
import {ActionsColumn, Column, IdColumn, Relative, Breadcrumb} from "react-admin-base-adminkit";

export default function Suppliers() {
    const user = useUser();

    return <Breadcrumb
        data={
            [
                {
                    href: '/supplier',
                    name: 'Tedarikçi'
                }
            ]
        }
    >
        <Card>
            <CardBody>
                <CRUD Component={SupplierEntity} url="/supplier">
                    <thead>
                    <tr>
                        <IdColumn/>
                        <Column sort="name">Adı</Column>
                        <Column>Logo</Column>
                        <ActionsColumn/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        row => <tr key={row.id}>
                            <td className="min text-center">{row.id}</td>
                            <td>{row.name}</td>
                            <td>
                                { row.logo && <Relative>
                                    <img width={"15%"}
                                         src={row.logo.access_url} alt={row.name}/>
                                </Relative> }
                            </td>
                            <CRUDActions
                                id={row.id}
                                edit={user.type === 512}
                                del={(user.type === 512) && "/supplier/" + row.id}
                            />
                        </tr>
                    }
                    </tbody>
                </CRUD>
            </CardBody>
        </Card>
    </Breadcrumb>;
}
