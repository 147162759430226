import React from 'react';
import {Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import {EntityEditor} from 'react-admin-base-adminkit';
import {Breadcrumb} from 'react-admin-base-adminkit';
import {Validator} from 'react-admin-base-adminkit';

export default function Edit() {
    const entity = useEntity('/user', 'me');
    const [data, setData, loading, save] = entity;

    return <>
        <Breadcrumb
            title="Profil"
            data={
                [
                    {
                        href: '/profil',
                        name: 'Profil'
                    }
                ]
            }
        />
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">İsim</Label>
                        </Col>
                        <Col md="4">
                            <Validator name="name" type="required">
                                <Input
                                    type="text"
                                    value={data.first_name}
                                    onChange={a => setData({first_name: a.currentTarget.value})}
                                />
                            </Validator>
                        </Col>
                        <Col md="2">
                            <Label htmlFor="text-input">Soyisim</Label>
                        </Col>
                        <Col md="4">
                            <Validator name="surname" type="required">
                                <Input
                                    type="text"
                                    value={data.last_name}
                                    onChange={a => setData({last_name: a.currentTarget.value})}
                                />
                            </Validator>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">E-Mail</Label>
                        </Col>
                        <Col md="10">
                            <Validator name="email" type="required">
                                <Input type="text" value={data.email}
                                       onChange={a => setData({email: a.currentTarget.value})}/>
                            </Validator>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">Şifre</Label>
                        </Col>
                        <Col md="10">
                            <Input
                                type="password"
                                value={data.password}
                                autocomplete="new-password"
                                onChange={a => setData({password: a.currentTarget.value})}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </EntityEditor>
    </>;
}
