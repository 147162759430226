import {BootstrapTable, Actions, ActionsColumn, Column, IdColumn} from 'react-admin-base-adminkit';

import {Breadcrumb} from 'react-admin-base-adminkit';
import {Card, CardBody} from "reactstrap";

export default function ProductTypeList() {
    return <Breadcrumb
        title="Product Types"
        data={
            [
                {
                    href: '/product-types',
                    name: 'Ürün Tipleri'
                }
            ]
        }
    >
        <Card>
            <CardBody>
                <BootstrapTable url="/product_type" add="/product-type/create">
                    <thead>
                        <tr>
                            <IdColumn />
                            <Column sort="name">Adı</Column>
                            <ActionsColumn />
                        </tr>
                    </thead>
                    <tbody>
                    {
                        row => <tr key={row.id}>
                            <td className="min text-center">{row.id}</td>
                            <td>{row.name}</td>
                            <Actions
                                edit={"/product-type/" + row.id}
                                del={"/product_type/" + row.id}
                            />
                        </tr>
                    }
                    </tbody>
                </BootstrapTable>
            </CardBody>
        </Card>

    </Breadcrumb>;
}
