import React, {useState, useCallback} from 'react';
import useBids from "./BidProvider";
import {Card, Row, Col, Input} from 'reactstrap';
import {useAuth} from 'react-admin-base';
import {Breadcrumb, Relative, MultiFilePicker} from 'react-admin-base-adminkit';
import Avatar from 'react-avatar';
import {Link} from "react-router-dom";

function trToEng(str) {
    return str.replace('Ğ', 'g')
        .replace('Ü', 'u')
        .replace('Ş', 's')
        .replace('I', 'i')
        .replace('İ', 'i')
        .replace('Ö', 'o')
        .replace('Ç', 'c')
        .replace('ğ', 'g')
        .replace('ü', 'u')
        .replace('ş', 's')
        .replace('ı', 'i')
        .replace('ö', 'o')
        .replace('ç', 'c')
        .toLocaleLowerCase('tr');
}

function getTimePart(date) {
    date = new Date(date);
    return date.getHours() + ":" + date.getMinutes();
}

function Bid({value, isActive, onClick}) {


    return <a className={"list-group-item list-group-item-action border-0" + (isActive ? ' chat-active' : '')} onClick={onClick}>
        <div className="d-flex align-items-start">
            <Avatar email={value.user.email} className="rounded-circle mr-1" size="40" round={true}/>
            <div className="flex-grow-1 ml-3">
                {value.user.first_name} {value.user.last_name}
                <div className="small">{value.product.name}</div>
            </div>
        </div>
    </a>;
}

function ChatBox({value}) {
    const [api] = useAuth();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const send = useCallback(async function (e) {
        e.preventDefault();

        if (!message)
            return ;

        setLoading(true);
        try {
            await api.tokenized.put('/bid/' + value.id + '/messages', { message, attachments });
            setMessage('');
            setAttachments([]);
        } finally {
            setLoading(false);
        }
    }, [api, value, message, setMessage, setLoading, attachments, setAttachments]);

    if (!value)
        return <div className="m-5">
            Lütfen seçiniz...
        </div>;

    const user_avatar = <Avatar email={value.user.email} name={value.user.first_name + " " + value.user.last_name} className="rounded-circle mr-1" size="40" />;

    return <>
        <div className="py-2 px-4 border-bottom d-none d-lg-block">
            <div className="d-flex align-items-center py-1">
                <div className="position-relative">
                    { user_avatar }
                </div>
                <div className="flex-grow-1 pl-3">
                    <strong>{value.user.first_name} {value.user.last_name}</strong>
                    <div className="text-muted small"><em>{value.product.name}</em></div>
                </div>
            </div>
        </div>
        <div className="position-relative flex-full">
            <div className="chat-messages p-4 flex-full">
                {[...value.messages].reverse().map(msg => <div key={msg.id} className={"chat-message-" + (msg.is_user ? 'left' : 'right') + " pt-4"}>
                    <div>
                        { !!msg.is_user && user_avatar }
                        { !msg.is_user && value.supplier.logo && <Relative>
                            <img src={value.supplier.logo.access_url} width="40" height="40" className="rounded-circle mr-1" alt={value.supplier.name} />
                        </Relative>}
                        <div className="text-muted small text-nowrap mt-2 text-center">{getTimePart(msg.created_at)}</div>
                    </div>
                    <div className={"flex-shrink-1 bg-light rounded py-2 px-3 " + (msg.is_user ? ' ml-3' : 'mr-3')}>
                        <div className="font-weight-bold mb-1">{msg.is_user ? value.user.first_name + " " + value.user.last_name : value.supplier.name}</div>
                        {msg.message}
                        { msg.attachments.length > 0 && <div className="mt-3">
                            <b>Ekler:</b>
                            <ul className="my-0">
                                {msg.attachments.map(a => <li>
                                    <Relative>
                                        <a href={a.access_url} title={a.name} target="_blank">{a.name}</a>
                                    </Relative>
                                </li>)}
                            </ul>
                        </div> }
                    </div>
                </div>)}
                <div className="mt-3">
                    <div className="pt-3">
                        <b>Teklif İstenilen Ürün:</b> <Link to={"/product/" + value.product.id} target="_blank">{value.product.name}</Link> <i className="text-muted">(Bu link tekersepeti.com.tr sayfasindaki urun alanina gidecektir.)</i>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex-grow-0 py-3 px-4 border-top">
            <form onSubmit={send}>
                <div className="input-group">
                    <Input type="text" disabled={loading} placeholder="Type your message" value={message || ''} onChange={a => setMessage(a.currentTarget.value)}/>
                    <button type="submit" disabled={loading} className="btn btn-primary">Gönder</button>
                </div>
                <div className="mt-2">
                    <MultiFilePicker
                        accepts="*/*"
                        value={attachments}
                        onChange={a => setAttachments(a)}
                    />
                </div>
            </form>
        </div>
    </>;
}

export default function Bids() {
    const bids = useBids();
    const [activeBidId, setActiveBidId] = useState();
    const [search, setSearch] = useState();

    if (!bids)
        return null;

    const trSearch = search && trToEng(search);
    const filteredBids = search ? bids.filter(a =>
        trToEng(a.user.first_name + " " + a.user.last_name + " " + a.supplier.name + " " + a.product.name)
            .indexOf(trSearch) !== -1
    ) : bids;
    const activeBid = !activeBidId ? bids[0] : bids.find(b => b.id === activeBidId);

    return <Breadcrumb
        data={
            [
                {
                    href: '/bids',
                    name: 'Bids'
                }
            ]
        }
    >
        <Card className="flex-full">
            <Row className="no-gutters">
                <Col size="12" lg="5" xl="3" className="border-right flex-full">
                    <div className="px-4 d-none d-md-block">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <Input type="text" className="my-3" placeholder="Arama..." value={search} onChange={a => setSearch(a.currentTarget.value)}/>
                            </div>
                        </div>
                    </div>
                    {filteredBids.map(a => <Bid key={a.id} value={a} isActive={a === activeBid} onClick={() => setActiveBidId(a.id)}/>)}
                </Col>
                <Col size="12" lg="7" xl="9" className="flex-full">
                    <ChatBox value={activeBid}/>
                </Col>
            </Row>
        </Card>
    </Breadcrumb>;
}
