import React from 'react';
import {Card, CardBody, FormGroup, Input, Label} from "reactstrap";
import {useEntity, makeSlug} from "react-admin-base";
import {EntityEditor, Breadcrumb, Validator} from 'react-admin-base-adminkit';
import {Redirect} from 'react-router-dom';
import {useCategoryParents} from "./List";

export default function EditCreate({match}) {
    const { id, parent_id } = match.params;

    const parentCategories = useCategoryParents(parent_id || id);
    const entity = useEntity('/category', id, { parent: parent_id && { id: parent_id } });
    const [data, setData] = entity;

    if (!parentCategories)
        return null;

    if (!data) {
        return null;
    }

    return <Breadcrumb
        data={
            [
                {
                    href: '/category',
                    name: 'Kategoriler'
                }
            ]
            .concat(parentCategories.map((category, index) => (id && index === parentCategories.length - 1 ? {
                href: '/category/' + category.id + '/edit',
                name: category.name + ' (Düzenle)'
            } : {
                href: '/category/' + category.id,
                name: category.name
            })))
            .concat([
                !id && {
                    href: parent_id ? `/category/${parent_id}/create` : '/category/create',
                    name: 'Ekle'
                }
            ])
        }
    >
        { data && data.id && +id !== +data.id && <Redirect to={"/category/" + data.id + "/edit"}/> }
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <FormGroup>
                        <Label>İsim</Label>
                        <Validator name="name" type="required">
                            <Input
                                type="text"
                                value={data.name || ''}
                                onChange={a => setData({
                                    name: a.currentTarget.value,
                                    slug: makeSlug(data.name || '') === (data.slug || '') ? makeSlug(a.currentTarget.value) : data.slug
                                })}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label>Link</Label>
                        <Validator name="slug" type="required">
                            <Input
                                type="text"
                                value={data.slug || ''}
                                onChange={a => setData({slug: a.currentTarget.value})}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label>Sıra</Label>
                        <Validator name="order" type="required|number">
                            <Input
                                type="text"
                                value={data.order || ''}
                                onChange={a => setData({order: a.currentTarget.value})}
                            />
                        </Validator>
                    </FormGroup>
                </CardBody>
            </Card>
        </EntityEditor>
    </Breadcrumb>;
}
