import React from 'react';
import {EntityEditor} from 'react-admin-base-adminkit';
import {Button, Card, CustomInput, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import {Breadcrumb} from 'react-admin-base-adminkit';
import {Redirect} from 'react-router-dom';
import {Validator} from 'react-admin-base-adminkit';
import CategorySelect from "../../Components/CategorySelect";
import {ProductTypeSelect} from "../../Components/Selects";
import { MultiFilePicker } from 'react-admin-base-adminkit';
import { makeSlug } from 'react-admin-base';
import {CKEditorWithUpload as CKEditor} from 'react-admin-base-ckeditor';
import PropertySelector from "./PropertySelector";

export default function EditCreate({match}) {
    const id = match.params.id;

    const entity = useEntity('/product', id);
    const [data, setData] = entity;

    return <Breadcrumb
        title="Ürünler"
        data={
            [
                {
                    href: '/product',
                    name: 'Ürünler'
                }
            ]
        }
    >
        { data && data.id && +id !== +data.id && <Redirect to={"/product/" + data.id}/> }
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <FormGroup>
                        <CustomInput
                            id="aktiv"
                            type="switch"
                            checked={!!data.active}
                            onChange={a => setData({active: !data.active})}
                            inputProps={{'aria-label': 'secondary checkbox'}}
                            label="Aktif mi?"
                        />
                    </FormGroup>
                    <Row>
                        <Col md="7">
                            <FormGroup>
                                <Label>İsim</Label>
                                <Validator name="name" type="required">
                                    <Input
                                        type="text"
                                        value={data.name || ''}
                                        onChange={a => setData({
                                            name: a.currentTarget.value,
                                            slug: makeSlug(data.name || '') === (data.slug || '') ? makeSlug(a.currentTarget.value) : data.slug
                                        })}
                                    />
                                </Validator>
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>link</Label>
                                        <Validator name="slug" type="required">
                                            <Input
                                                type="text"
                                                value={data.slug || ''}
                                                onChange={a => setData({slug: a.currentTarget.value})}
                                            />
                                        </Validator>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Kod</Label>
                                        <Validator name="code" type="required">
                                            <Input
                                                type="text"
                                                value={data.code || ''}
                                                onChange={a => setData({code: a.currentTarget.value})}
                                            />
                                        </Validator>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label>Alt başlık</Label>
                                <Input
                                    type="text"
                                    value={data.subtitle || ''}
                                    onChange={a => setData({
                                        subtitle: a.currentTarget.value,
                                    })}
                                />
                            </FormGroup>


                            <FormGroup>
                                <Label>Ürün tipi</Label>
                                <Validator name="product_type" type="required">
                                    <ProductTypeSelect
                                        value={data.product_type}
                                        onChange={a => setData({ product_type: a })}
                                    />
                                </Validator>
                            </FormGroup>

                            <FormGroup>
                                <Label>Kategori</Label>
                                <Validator name="category" type="required">
                                    <CategorySelect
                                        value={data.category}
                                        onChange={a => setData({ category: a })}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                        <Col md="5">

                            <FormGroup>
                                <Label>Fotoğrafları</Label>
                                <MultiFilePicker
                                    accepts="image/*"
                                    value={data.photos}
                                    onChange={a => setData({ photos: a })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>Açıklama</Label>
                        <CKEditor
                            value={data.description || ''}
                            onChange={a => setData({ description: a })}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
            <PropertySelector
                product_type={data.product_type}
                value={data.attributes || []}
                onChange={a => setData({ attributes: a })}
            />
        </EntityEditor>
    </Breadcrumb>;
}
