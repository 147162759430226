import React, {useCallback, useContext, useRef, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import { BootstrapDataTable as BootstrapTable, Actions,LoadingButton} from "react-admin-base-adminkit";
import {Alert, Button, Form, Modal, ModalFooter, ModalHeader} from "reactstrap";
import {ValidatorProvider} from "react-admin-base";

export function ModalEntityEditor({ entity, title, url, onReload, disabled, children }) {
    const [ , , save, loading ] = entity;

    const [ open, setOpen ] = useState(true);
    const [ saved, setSaved ] = useState(false);
    const [ error, setError ] = useState(false);

    const onSubmit = useCallback(async function(e) {
        e.stopPropagation();
        e.preventDefault();


        if (saved) {
            setSaved(false);
        }

        if (error) {
            setError(null);
        }

        try {
            let data = await save();
            if (onReload) {
                console.log('Reload: ', data);
                await onReload(data);
            }

            if (url) {
                setSaved(true);
            }
        } catch(e) {
            console.error(e);
            setError((e.response && e.response.data && e.response.data.message) || (error.data && error.data.message) || e.data || e.message || e);
        }
        finally
        {

        }
    }, [save, saved, error, onReload, url]);

    return <>
        { (saved || !open) && <Redirect to={url} />}
        <Modal isOpen toggle={() => url ? setOpen(false) : onReload(null)} fade={false}>
            { title && <ModalHeader toggle={() => url ? setOpen(false) : onReload(null)}>
                <b>{ title }</b>
            </ModalHeader> }
            <ValidatorProvider>
                <Form onSubmit={onSubmit} disabled={!!loading || disabled}>
                    <fieldset disabled={!!loading || disabled}>
                        { children }
                    </fieldset>
                    <ModalFooter>
                        { error && <Alert color="danger" toggle={() => setError(null)} style={{ display: 'block', width: '100%' }}>{ error }</Alert>}
                        <LoadingButton block loading={loading} type="submit" color="primary">
                            <i className="fas fa-save" />{' '}Save
                        </LoadingButton>
                        <Button block outline color="danger" onClick={(e) => { e.preventDefault(); (url ? setOpen(false) : onReload(null)); }}>
                            <i className="fas fa-times-circle" />{' '}Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </ValidatorProvider>
        </Modal>
    </>;
}

const UrlContext = React.createContext();

export function CRUDActions({ id, edit, del, children }) {
    const url = useContext(UrlContext);

    return <Actions edit={edit && (url + "/" + id + "/edit")} del={del}>
        { children }
    </Actions>;
}

export default function CRUD(props) {
    const ref = useRef(null);
    const { id, url, apiUrl, Component, defaultParams } = props;

    var reload = useCallback(async function() {
        if (ref.current) {
            ref.current({});
        }
    }, [ref]);

    return <UrlContext.Provider value={url}>
        <Route path={url+"/create"} render={props => <Component url={url} onReload={reload} {...(defaultParams || {})} />} />
        <Route path={url+"/:id/edit"} render={props => <Component url={url} onReload={reload} id={props.match.params.id} {...(defaultParams || {})} />} />
        { id && <Route path={url+"/edit"} render={props => <Component url={url} onReload={reload} id={id} {...(defaultParams || {})} />} />}
        <BootstrapTable innerRef={ref} add={url+"/create"} {...props} url={apiUrl || url} />
    </UrlContext.Provider>;
}
