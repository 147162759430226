
import React from 'react';
import {Card,Table,Input,CustomInput} from 'reactstrap';
import { MultiValue } from 'react-admin-base';
import {AttributeValueSelect, PropertySelect} from "../../Components/Selects";

function ValueSelect({ attribute, value, onChange }) {

    console.log(attribute.name, attribute.type);

    switch (attribute.type) {
        case 1:
            return <CustomInput
                type="checkbox"
                id={"attr_" + attribute.id}
                checked={!!value.has}
                onChange={a => onChange({ has: !value.has })}
            />;
        case 2:
            return <Input
                type="text"
                value={value.text}
                onChange={a => onChange({ text: a.currentTarget.value })}
            />;
        case 3:
            return <AttributeValueSelect
                attribute_id={attribute.id}
                value={value.value}
                onChange={a => onChange({ value: a })}
            />;
        case 4:
            return <AttributeValueSelect
                attribute_id={attribute.id}
                isMulti
                value={value.values}
                onChange={a => onChange({ values: a })}
            />;
        default:
    }

    return null;
}

export default function PropertySelector({ product_type, value, onChange }) {
    if (!product_type)
        return null;

    const selected_ids = (value || []).map(a => a.attribute.id);

    return <Card>
        <Table hover stripped>
            <thead>
                <th className="min">#</th>
                <th style={{ width: '20%' }}>Grup</th>
                <th style={{ width: '30%' }}>Seçenek</th>
                <th>Değer</th>
            </thead>
            <tbody>
                <MultiValue value={value} onChange={onChange}>
                    {
                        (value, setValue, index) => <tr>
                            <th>{index + 1}</th>
                            <td>
                                { value && value.attribute && value.attribute.attribute_group.name }
                            </td>
                            <th>
                                <PropertySelect
                                    product_type_id={product_type.id}
                                    value={value && value.attribute}
                                    onChange={a => setValue(a == null ? null : { attribute: a })}
                                    selected_ids={selected_ids}
                                />
                            </th>
                            <th>
                                { value && value.attribute && <ValueSelect
                                    attribute={value.attribute}
                                    value={value}
                                    onChange={a => setValue({ ...value, ...a })}
                                /> }
                            </th>
                        </tr>
                    }
                </MultiValue>
            </tbody>
        </Table>
    </Card>
}
