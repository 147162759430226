import {BootstrapTable, Actions, ActionsColumn, Column, IdColumn} from 'react-admin-base-adminkit';

import {Breadcrumb} from 'react-admin-base-adminkit';
import {Card, CardBody,CustomInput} from "reactstrap";
import { useAuth } from 'react-admin-base';
import React, { useState, useCallback } from 'react';
import {ProductFilters} from "../Product/List";

function ToggleSupply({ value }) {
    const [ api ] = useAuth();
    const [ loading, setLoading ] = useState(false);

    const url = `/product/${value.product.id}/supplier`;

    const onChange = useCallback(async function(e) {
        setLoading(true);
        try {
            if (!value.enabled) {
                await api.tokenized.put(url);
            } else {
                await api.tokenized.delete(url);
            }

            value.enabled = !value.enabled;
        } finally {
            setLoading(false);
        }
    }, [ api, url, value ]);

    return <CustomInput
        type="checkbox"
        id={'product_' + value.product.id}
        checked={value.enabled}
        onChange={onChange}
        disabled={loading}
    />;
}

export default function Supplies() {
    return <Breadcrumb
        title="Ürün Listesi"
        data={
            [
                {
                    href: '/product',
                    name: 'Ürünler'
                }
            ]
        }
    >
        <Card>
            <CardBody>
                <BootstrapTable url="/product/supplier">
                    <thead>
                        <tr>
                            <IdColumn />
                            <Column className="min">Aktif mi?</Column>
                            <Column className="min">Türü</Column>
                            <Column sort="name">Adı</Column>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            row => <tr key={row.id}>
                                <td className="min text-center">{row.product.id}</td>
                                <td className="min text-center"><ToggleSupply value={row} /></td>
                                <td>{ row.product.product_type.name }</td>
                                <td>{row.product.name}</td>
                            </tr>
                        }
                    </tbody>
                    <ProductFilters />
                </BootstrapTable>
            </CardBody>
        </Card>
    </Breadcrumb>;
}
