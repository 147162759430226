import React from 'react';
import {FormGroup, Input, Label, ModalBody, CustomInput} from "reactstrap";
import {ModalEntityEditor} from "../../Components/CRUD";
import {useEntity} from "react-admin-base";
import {Validator, SingleFilePicker} from "react-admin-base-adminkit";

export default function CampaignEntity({url, onReload, id}) {
    const entity = useEntity('/campaign', id, {});
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor title="Kampanya Yönetimi" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <CustomInput
                    id="aktiv"
                    type="switch"
                    checked={!!data.active}
                    onChange={a => setData({active: !data.active})}
                    inputProps={{'aria-label': 'secondary checkbox'}}
                    label="Aktif mi?"
                />
            </FormGroup>
            <FormGroup>
                <Label>Sıra</Label>
                <Validator name="order" type="required">
                    <Input
                        type="text"
                        value={data.order || ''}
                        onChange={a => setData({order: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Başlık</Label>
                <Validator name="title" type="required">
                    <Input
                        type="text"
                        value={data.title || ''}
                        onChange={a => setData({title: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>URL</Label>
                <Validator name="url" type="required">
                    <Input
                        type="text"
                        value={data.url || ''}
                        onChange={a => setData({url: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Fotoğraf</Label>
                <SingleFilePicker
                    accepts="image/*"
                    value={data.photo}
                    onChange={photo => setData({photo})}
                />
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}
