
import React, {useCallback} from 'react';
import { useAuth } from 'react-admin-base';
import {ApiSelect} from "react-admin-base-adminkit";

export function ProductTypeSelect(props) {
    return <ApiSelect {...props} url="/product_type" />;
}

export function SupplierSelect(props) {
    return <ApiSelect {...props} url="/supplier" />;
}

export function PropertySelect(props) {
    const { product_type_id, selected_ids } = props;

    const group = useCallback(function (rows) {
        return (rows || []).map(row => ({
            label: row.name,
            options: (row.attributes || [])
                .filter(a => selected_ids.indexOf(a.id) === -1)
                .map(a => ({ ...a, attribute_group: row }))
        }));
    }, [selected_ids]);

    return <ApiSelect {...props} group={group} url={"/product_type/" + product_type_id + '/groups'} />;
}

export function AttributeValueSelect(props) {
    const [ api ] = useAuth();
    const { attribute_id } = props;

    const url = "/attribute/" + attribute_id + "/values";

    const onCreateOption = useCallback(async function(input) {
        const data = await api.tokenized.put(url, { value: input });
        return data.data;
    }, [api, url]);

    return <ApiSelect
        nameKey="value"
        url={url}
        onCreateOption={onCreateOption}
        {...props}
    />;
}
