import "react-admin-base-adminkit/assets/app.css";
import './App.scss';

import {App, Authorized, ForgotPasswordRoute, LoginRoute, NotAuthorized, AuthProvider} from 'react-admin-base';
import {Login, Reset, MainLayout, LanguageProvider} from 'react-admin-base-adminkit';
import MenuSidebar from "./MenuSidebar";
import Router from "./Router";
import MenuHeader from "./MenuHeader";
import Footer from "./Footer";
import {UserProvider} from "./Components/UserProvider";
import UploadConfig from "./UploadConfig";
import WebSocketProvider from "./Components/WebSocketProvider";

function BaseApp() {
    return (
        <App
            id="tekersepeti"
            name="Teker Sepeti"
            endpoint={process.env.REACT_APP_ENDPOINT}
            logo="/logo.svg"
        >
            <LanguageProvider defaultLanguage="tr" languages={{tr: {}}}>
                <AuthProvider
                    tokenEndpoint="/token"
                    client_id="3da4385d-5166-4c1f-9c4b-49afe82be47e"
                    client_secret="123456"
                >
                    <NotAuthorized>
                        <LoginRoute>
                            <Login/>
                        </LoginRoute>
                        <ForgotPasswordRoute>
                            <Reset/>
                        </ForgotPasswordRoute>
                    </NotAuthorized>
                    <Authorized>
                        <UserProvider>
                            <UploadConfig>
                                <WebSocketProvider>
                                    <MainLayout>
                                        <MenuHeader/>
                                        <MenuSidebar/>
                                        <Router/>
                                        <Footer/>
                                    </MainLayout>
                                </WebSocketProvider>
                            </UploadConfig>
                        </UserProvider>
                    </Authorized>
                </AuthProvider>
            </LanguageProvider>
        </App>
    );
}

export default BaseApp;
